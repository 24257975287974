/**
캘린더 Redefine CSS
 */
.react-calendar {
    width: 100%;
    max-width: 100%;
    background: var(--basic-white);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
    font-size: 1.2rem;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar__month-view > div > div {
    /*border: 1px solid var(--basic-shade-400);*/
}

.react-calendar__month-view__weekdays {
    /*border-bottom: 1px solid var(--basic-shade-400);*/
}

.react-calendar__month-view__weekdays__weekday {
    width: 100%;
    height: inherit;
    padding: 10px 20px;
    background: var(--basic-shade-200);
    /*border: 1px solid var(--basic-shade-400);*/
    /*border-bottom: 0px;*/
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: default;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
}

.react-calendar__tile {
    max-width: 100%;
    padding: 5px 10px;
    background: none;
    line-height: 16px;
    font: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    font-size: 14px;
    min-height: 108px;
    border: 1px solid var(--basic-white) !important;
    border-spacing: 0;
    cursor: pointer;
}

.react-calendar__tile

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    border-radius: 5px;
    background: var(--basic-shade-200);
    cursor: default;
}

.react-calendar__tile--now {
    border-radius: 5px;
    background: #6b2fdd38;
}

.react-calendar__tile--now:hover,
.react-calendar__tile--now:focus {
    border: 1px solid var(--basic-shade-850) !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    border: 1px solid var(--basic-white) !important;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    border: 1px solid var(--basic-white) !important;
    background: inherit !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    border: 1px solid var(--basic-white) !important;
    background: inherit !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.react-calendar__tile > abbr {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border-radius: 5px;
}

.react-calendar__tile > div {
    min-height: 75px;
}