/**
공통 CSS 파일
 */
:root {
  --basic-white: #fff;
  --basic-dark: #13062d;
  --basic-black: #000;
  --basic-shade-100: #fbfbfc;
  --basic-shade-200: #f4f4f9;
  --basic-shade-300: #e9e9f0;
  --basic-shade-400: #d4d4df;
  --basic-shade-500: #babccd;
  --basic-shade-600: #979bae;
  --basic-shade-700: #757b92;
  --basic-shade-800: #4B526A;
  --basic-shade-850: #404465;
  --purple-shade-100: #f8f7fd;
  --purple-shade-200: #f2ecff;
  --purple-primary: #6b2fdd;
  --basic-box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
}

html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

/**
레이아웃
 */
.layout-page-content {
  max-width: unset !important;
}

/**
레이아웃 - TNB
 */
.navbar-content-container {
  max-width: unset !important;
}

/**
레이아웃 - 검색영역
 */
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-box {
  display: flex;
  align-items: center;
}

.search-btn-area {
  display: flex;
  justify-content: flex-end;
  height: 56px;
}

/**
버튼
 */
.btn-comm {
  width: 100%;
  height: 56px;
}

.tnb-btn-area, .bnb-btn-area {
  display: flex;
  justify-content: flex-end;
}

/**
칩
 */
.item-chip {
  margin: 5px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  opacity: 1 !important;
}

.item-chip:not(.disabled):hover {
  cursor: pointer;
  box-shadow: var(--basic-box-shadow);
}

.item-chip-apply {
  background-color: #ffe082 !important;
}

item-chip-icon {
  background: #fff;
  border-radius: 25px;
  position: relative;
  opacity: 0.5;
  color: #000 !important;
}

.item-chip-accept {
  background-color: #c5e1a5 !important;
}

/**
Loading Indicator
 */
.progress-area {
  position: relative;
}

/**
등록화면
 */
.form-wrap {
  margin: 1.25rem 0 !important;
}

.form-wrap-box:not(:first-child) {
  margin-top: 20px;
}

.form-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.form-box {
  display: flex;
  align-items: center;
}

/**
첨부파일영역
 */
.dropzone {
  border-radius: 5px;
  border: 1px dashed var(--purple-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--purple-primary);
  cursor: pointer;
  min-height: 100px;
  padding: 0 20px;
}

.dropzone:hover, .dropzone:focus-within {
  background: rgba(107, 47, 221, 0.06);
}